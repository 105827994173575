
export const reviewData = [
  {   name:"Kevina2",
      url: "https://raw.githubusercontent.com/nimsara1999/reviews/refs/heads/main/kevin2.png"
  },
  {
      name:"Omatagalpa",
      url:"https://raw.githubusercontent.com/nimsara1999/reviews/refs/heads/main/omatagalpa.png"
  },
  {
      name:"diroshan",
      url:"https://raw.githubusercontent.com/nimsara1999/reviews/refs/heads/main/diroshan.png"
  },
  {
      name:"kimyon",
      url:"https://raw.githubusercontent.com/nimsara1999/reviews/refs/heads/main/kimyon.png"
  },
  {
      name:"Kevin1",
      url:"https://raw.githubusercontent.com/nimsara1999/reviews/refs/heads/main/kevin1.png"
  },
  {
      name:"Yenushim",
      url:"https://raw.githubusercontent.com/nimsara1999/reviews/refs/heads/main/yenushim.png"
  },
  {
      name:"Upwork1",
      url:"https://raw.githubusercontent.com/nimsara1999/reviews/refs/heads/main/upwork1.png"
  },
  {
      name:"Rohan",
      url:"https://raw.githubusercontent.com/nimsara1999/reviews/refs/heads/main/rohan.png"
  }
];

